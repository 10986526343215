import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Rifornimenti/';

class RifornimentiService {
    getRifornimentiVeicoli(enteId, userId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        parametri.append("userId", userId);
        UtilsRequest.get(API_URL + "GetRifornimentiVeicoli", parametri, 
        successFn,
        failFn
        );
    }

    getNuovoRifornimentoVeicolo(idVeicolo, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idVeicolo", idVeicolo);
        UtilsRequest.get(API_URL + "GetNuovoRifornimentoVeicolo", parametri, 
        successFn,
        failFn
        );
    }

    updateRifornimentoVeicolo(rifornimentoVeicolo, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateRifornimentoVeicolo", rifornimentoVeicolo, 
        successFn,
        failFn
        );
    }

    chiudiRifornimentoVeicolo(rifornimentoVeicolo, successFn, failFn){
        UtilsRequest.post(API_URL + "ChiudiRifornimentoVeicolo", rifornimentoVeicolo, 
        successFn,
        failFn
        );
    }

    sbloccaRifornimentoVeicolo(rifornimentoVeicolo, successFn, failFn){
        UtilsRequest.post(API_URL + "SbloccaRifornimentoVeicolo", rifornimentoVeicolo, 
        successFn,
        failFn
        );
    }
}

export default new RifornimentiService();