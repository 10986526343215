<template>
  <v-form ref="form" class="mx-2"  enctype="multipart/form-data" onkeydown="return event.key != 'Enter';">
    <v-container id="RIFORNIMENTI" fluid tag="section">
      <v-row>
        <v-col :cols="isEditRifornimenti ? '4' : '12'">
          <base-material-card
            color="white"
            icon="mdi-ev-station"
            iconcolor="darkred"
            :title="$t('rifornimenti.titolo')"
            class="px-5 py-3"
            backgroundcolor=""
            titlecolor="red"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('rifornimenti.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>
                <v-col align="end">
                  <v-btn 
                    color="darkred" 
                    width="180px"
                    @click="onExportGrid">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblMainRifornimenti"
              :items="rifornimenti.source"
              :headers="headersColumns"
              :search="search"
              :value="[rifornimenti.selected]"
              @click:row="onTableRowClick"
              single-select
              :items-per-page="-1"
              class="elevation-1"
              :loading="isloading_rifornimenti"
              loading-text="Caricamento dati in corso..."
              item-key="ultimoRifornimento.id"
            >
              <template v-slot:[`item.ultimoRifornimento.nCosto1`]="{ item }">
                <span v-if="item.ultimoRifornimento.nCosto1 != null">{{ '€' +  item.ultimoRifornimento.nCosto1.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "€1,") }}</span>
              </template>
              <template v-slot:[`item.ultimoRifornimento.nCosto2`]="{ item }">
                <span v-if="item.ultimoRifornimento.nCosto2 != null">{{ '€' +  item.ultimoRifornimento.nCosto2.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "€1,") }}</span>
              </template>
            </v-data-table>

          </base-material-card>
        </v-col>

        <v-col v-if="isEditRifornimenti" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-ev-station"
            :title="$t('page.rifornimento.titolo')"
            class="px-5 py-3"
          >
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab v-for="item in rifornimentiTabs" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in rifornimentiTabs" :key="item" eager>
                <v-card v-if="item == 'Ultimo rifornimento'" flat>
                  <v-card-title
                    class="black--text"
                    style="margin-bottom: 20px; margin-top: 20px"
                    >{{ $t("veicolo") }}
                  </v-card-title>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="rifornimenti.selected.cSigla"
                        :label="$t('sigla')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="rifornimenti.selected.cTarga"
                        :label="$t('targa')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="rifornimenti.selected.marcaModelloVeicolo"
                        :label="$t('modello')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="rifornimenti.selected.ultimoRifornimento.nAnno"
                        :label="$t('anno')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="rifornimenti.selected.ultimoRifornimento.nMese"
                        :label="$t('mese')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="
                          rifornimenti.selected.ultimoRifornimento.statoRif
                        "
                        :label="$t('stato')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="
                          rifornimenti.selected.ultimoRifornimento.nKmInizioMese
                        "
                        :label="$t('kmIniziali')"
                        type="number"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="rifornimenti.selected.ultimoRifornimento.nKmFineMese"
                        :label="$t('kmFineMese')"
                        type="number"
                        :rules="[rules.required, rules.valueMin(rifornimenti.selected.ultimoRifornimento.nKmFineMese, 0), rules.valueMax(rifornimenti.selected.ultimoRifornimento.nKmFineMese, 999999)]"
                        v-on:blur="changeKmPercorsi"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="rifornimenti.selected.ultimoRifornimento.nKmPercorsi"
                        :label="$t('kmPercorsi')"
                        type="number"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="mx-4"></v-divider>

                  <v-row dense>
                    <v-col style="margin-right: 100px">
                      <v-card-title
                        class="black--text"
                        style="margin-bottom: 20px; margin-top: 20px"
                        >{{ $t("veicolo.carburante1") + ": " + rifornimenti.selected.ultimoRifornimento.tipoVeicoloCarbur1.cDescrizione }}
                      </v-card-title>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="rifornimenti.selected.quantitaCarb1"
                            :label="$t('quantitaLt')"
                            type="number"
                            min="1"
                            max="9999999"
                            :rules="[rules.valueMin(rifornimenti.selected.quantitaCarb1, 0), rules.valueMax(rifornimenti.selected.quantitaCarb1, 9999999)]"
                            step="0.01"
                            :disabled="shouldDisable"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <v-currency-field 
                            :label="$t('importoSpesoEuro')"
                            prefix="€"
                            v-model="rifornimenti.selected.valoreCarb1"
                            :disabled="shouldDisable"
                            />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-btn
                            tile
                            color="darkred"
                            :disabled="shouldDisable"
                            @click="onClickAddCarb1"
                          >
                            <v-icon left> mdi-plus-circle-outline </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="9">
                          <v-alert
                            id="alertCarb1"
                            shaped
                            :value="isAlertCarb1Visible"
                            :type="alertType"
                            dismissible
                          >
                            {{ messaggioAlert }}
                          </v-alert>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-data-table
                            dense
                            hide-default-footer
                            :items="rifornimenti.selected.ultimoRifornimento.listaDettaglio1"
                            :headers="headersCarburanti1"
                            :value="[carb1Sel]"
                            single-select
                            item-key="guid"
                            class="elevation-1"
                            height="250"
                          >
                            <template v-slot:[`item.nCosto`]="{ item }">
                              <span v-if="item.nCosto != null">{{ '€' +  item.nCosto.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "€1,") }}</span>
                            </template>

                            <template v-slot:top>
                              <v-dialog
                                v-model="dialogDeleteCarb1"
                                max-width="500px"
                              >
                                <v-card>
                                  <v-card-title class="text-h5"
                                    >Sei sicuro di voler cancellare l'elemento
                                    selezionato?
                                  </v-card-title>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeDeleteCarb1"
                                      >Annulla</v-btn
                                    >
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="deleteItemConfirmCarb1"
                                      >OK</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                v-show="!shouldDisable"
                                small
                                @click="deleteItemCarb1(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="rifornimenti.selected.ultimoRifornimento.nQuantita1"
                            :label="$t('quantitaTotale')"
                            type="number"
                            disabled
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <v-currency-field 
                            :label="$t('importoSpesoEuro')"
                            prefix="€"
                            v-model="rifornimenti.selected.ultimoRifornimento.nCosto1"
                            disabled
                            />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="rifornimenti.selected.ultimoRifornimento.tipoVeicoloCarbur2.id != -1">
                      <v-card-title
                        class="black--text"
                        style="margin-bottom: 20px; margin-top: 20px"
                        >{{ $t("veicolo.carburante2") + ": " + rifornimenti.selected.ultimoRifornimento.tipoVeicoloCarbur2.cDescrizione }}
                      </v-card-title>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="rifornimenti.selected.ultimoRifornimento.quantitaCarb2"
                            :label="$t('quantitaLt')"
                            type="number"
                            min="1"
                            max="9999999"
                            step="0.01"
                            :rules="[rules.valueMin(rifornimenti.selected.ultimoRifornimento.quantitaCarb2, 0), rules.valueMax(rifornimenti.selected.ultimoRifornimento.quantitaCarb2, 999999)]"
                            :disabled="shouldDisable"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <v-currency-field 
                            :label="$t('importoSpesoEuro')"
                            prefix="€"
                            v-model="rifornimenti.selected.ultimoRifornimento.valoreCarb2"
                            :disabled="shouldDisable"
                            />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-btn
                            tile
                            color="darkred"
                            :disabled="shouldDisable"
                            @click="onClickAddCarb2"
                            >
                            <v-icon left> mdi-plus-circle-outline </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="9">
                          <v-alert
                            id="alertCarb2"
                            shaped
                            :value="isAlertCarb2Visible"
                            :type="alertType"
                            dismissible
                          >
                            {{ messaggioAlert }}
                          </v-alert>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-data-table
                            dense
                            hide-default-footer
                            :headers="headersCarburanti2"
                            :items="rifornimenti.selected.ultimoRifornimento.listaDettaglio2"
                            class="elevation-1"
                            height="250"
                          >
                          <template v-slot:[`item.nCosto`]="{ item }">
                            <span v-if="item.nCosto != null">{{ '€' +  item.nCosto.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "€1,") }}</span>
                          </template>

                          <template v-slot:top>
                              <v-dialog
                                v-model="dialogDeleteCarb2"
                                max-width="500px"
                              >
                                <v-card>
                                  <v-card-title class="text-h5"
                                    >Sei sicuro di voler cancellare l'elemento
                                    selezionato?
                                  </v-card-title>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeDeleteCarb2"
                                      >Annulla</v-btn
                                    >
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="deleteItemConfirmCarb2"
                                      >OK</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                v-show="!shouldDisable"
                                small
                                @click="deleteItemCarb2(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="rifornimenti.selected.ultimoRifornimento.nQuantita2"
                            :label="$t('quantitaTotale')"
                            type="number"
                            disabled
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <v-currency-field 
                            :label="$t('importoSpesoEuro')"
                            prefix="€"
                            v-model="rifornimenti.selected.ultimoRifornimento.nCosto2"
                            disabled
                            />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-divider class="mx-4"></v-divider>

                  <v-row style="margin-top: 20px">
                    <v-col>
                      <v-textarea
                        v-model="rifornimenti.selected.ultimoRifornimento.xNote"
                        outlined
                        name="txtNote"
                        :label="$t('note')"
                        :readonly="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-divider v-show="!isUserUtiliz" class="mx-4"></v-divider>

                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-show="!isUserUtiliz"
                        v-model="rifornimenti.selected.kmLitro"
                        :label="$t('kmLitro')"
                        type="number"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-show="!isUserUtiliz"
                        v-model="rifornimenti.selected.kmLitroAnno"
                        :label="$t('kmLitroAnnuale')"
                        type="number"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                        <v-currency-field 
                          :label="$t('spesaTotale')"
                          prefix="€"
                          v-model="rifornimenti.selected.spesaTotale"
                          disabled
                          />
                    </v-col>
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Storico ultimo anno'" flat>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :items="rifornimenti.selected.storicoRifornimenti"
                        :headers="headersStoricoRif"
                        hide-default-footer
                        single-select
                        class="elevation-1"
                        style="max-height:600px; overflow:auto"
                        :items-per-page="-1"
                      >
                        <template v-slot:[`item.nCosto1`]="{ item }">
                          <span v-if="item.nCosto1 != null">{{ '€' +  item.nCosto1.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "€1,") }}</span>
                        </template>
                        <template v-slot:[`item.nCosto2`]="{ item }">
                          <span v-if="item.nCosto2 != null">{{ '€' +  item.nCosto2.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "€1,") }}</span>
                        </template>

                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-divider class="mx-4"></v-divider>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-col>
                <v-btn
                  tile
                  color="darkred"
                  v-if="!isHidden"
                  v-show="
                    rifornimenti.selected.ultimoRifornimento.statoRif ==
                      'Aperto' && !isEditActive
                  "
                  v-on:click="isHidden = true"
                  @click="onEditClick"
                >
                  <v-icon left> mdi-pencil </v-icon>
                  {{ $t("modifica") }}
                </v-btn>
                <v-btn
                  tile
                  color="darkred"
                  v-show="isEditActive"
                  v-if="isHidden"
                  @click="onSaveClick"
                >
                  <v-icon left> mdi-content-save </v-icon>
                  {{ $t("salva") }}
                </v-btn>
                <v-btn
                  tile
                  color="darkred"
                  @click="onNuovoMeseClick"
                  v-show="rifornimenti.selected.ultimoRifornimento.statoRif == 'Chiuso'" >
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  {{ $t("nuovoMese") }}
                </v-btn>
                <v-btn
                  tile
                  color="darkred"
                  @click="onSbloccaRifornimentoClick"
                  v-show="rifornimenti.selected.ultimoRifornimento.statoRif == 'Chiuso' && this.$store.state.roleSel.CCode == 'ADM'">
                  <v-icon left> mdi-smoke-detector-variant </v-icon>
                  {{ $t("sblocca") }}
                </v-btn>
                <v-btn
                  tile
                  color="darkred"
                  @click="onChiudiMeseClick"
                  v-show="rifornimenti.selected.ultimoRifornimento.statoRif == 'Aperto' && !isEditActive" >
                  <v-icon left> mdi-door-open </v-icon>
                  {{ $t("chiudiMese") }}
                </v-btn>
                <v-btn tile color="darkred" @click="closeDetail">
                  <v-icon left> mdi-door-open </v-icon>
                  {{ $t("chiudi") }}
                </v-btn>
              </v-col>
              <v-col align="end">
                <v-btn 
                  tile 
                  color="darkred"
                  @click="onVerificaClick"
                  v-show="this.$store.state.roleSel.CCode == 'ADM'">
                  <v-icon left> mdi-check-decagram-outline </v-icon>
                  {{ $t("verifica") }}
                </v-btn>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>

import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";

import Utils from "@/utils/Utils";

import TipologicheService from "@/service/TipologicheService";
import RifornimentiService from "@/service/RifornimentiService";
import UtentiService from "@/service/UtentiService";
import ExcelService from "@/service/ExcelService";

@Component({})
export default class Rifornimenti extends Vue {
  rifornimenti = {
    select: [],
    selected: null,
    source: [],
  };

  rules = Utils.rules;

  rifornimentiTabs = ["Ultimo rifornimento", "Storico ultimo anno"];

  headersColumnsMain = [
    {
      text: "Ente",
      align: "start",
      value: "enteDTO.cDescrizione",
    },
    { text: "Veicolo", value: "denomVeicolo" },
    { text: "Anno / Mese", value: "ultimoRifornimento.annoMese" },
    { text: "KM Fine mese", value: "ultimoRifornimento.nKmFineMese" },
    {
      text: "Carburante 1",
      value: "ultimoRifornimento.tipoVeicoloCarbur1.cDescrizione",
    },
    { text: "Quantità 1", value: "ultimoRifornimento.nQuantita1" },
    { text: "Importo 1", value: "ultimoRifornimento.nCosto1" },
    {
      text: "Carburante 2",
      value: "ultimoRifornimento.tipoVeicoloCarbur2.cDescrizione",
    },
    { text: "Quantità 2", value: "ultimoRifornimento.nQuantita2" },
    { text: "Importo 2", value: "ultimoRifornimento.nCosto2" },
    { text: "Stato", value: "ultimoRifornimento.statoRif" },
  ];

  headersColumnsRidotte = [
    {
      text: "Ente",
      align: "start",
      value: "enteDTO.cDescrizione",
    },
    { text: "Veicolo", value: "denomVeicolo" },
    { text: "Anno / Mese", value: "ultimoRifornimento.annoMese" },
    { text: "Stato", value: "ultimoRifornimento.statoRif" },
  ];

  headersCarburanti1 = [
    {
      text: "Quantità (lt)",
      align: "start",
      value: "nQuantita",
    },
    { text: "Valore (€)", value: "nCosto" },
    { text: "Azioni", value: "actions", sortable: false },
  ];

  headersCarburanti2 = [
    {
      text: "Quantità (lt)",
      align: "start",
      value: "nQuantita",
    },
    { text: "Valore (€)", value: "nCosto" },
    { text: "Actions", value: "actions", sortable: false },
  ];

  headersStoricoRif = [
    { text: "Anno / Mese", value: "annoMese" },
    { text: "Km iniziali", value: "nKmInizioMese" },
    { text: "Km Fine mese", value: "nKmFineMese" },
    { text: "Km percorsi", value: "nKmPercorsi" },
    { text: "Carburante 1", value: "tipoVeicoloCarbur1.cDescrizione" },
    { text: "Quantità 1", value: "nQuantita1" },
    { text: "Importo 1", value: "nCosto1" },
    { text: "Carburante 2", value: "tipoVeicoloCarbur2.cDescrizione" },
    { text: "Quantità 2", value: "nQuantita2" },
    { text: "Importo 2", value: "nCosto2" },
  ];

  // Dichiarazione Variabili
  tab = null;
  eUtente = null;

  isEditRifornimenti = false;
  isAlertDetailVisible = false;
  isAlertGridVisible = false;
  isAlertCarb1Visible = false;
  isAlertCarb2Visible = false;
  isloading_rifornimenti = false;
  isHidden = false;
  isEditActive = false;
  isUserUtiliz = true;
  shouldDisable = true;
  isCreatedCorrectly = false;

  dialogDeleteCarb1 = false;
  guidCarb1ToDelete = null;
  carb1Sel = null;

  dialogDeleteCarb2 = false;
  guidCarb2ToDelete = null;
  carb2Sel = null;

  dimension = 0;

  search = "";
  messaggioAlert = "";
  alertType = "error";

  mounted() {
    if (this.isCreatedCorrectly)
      this.onContainerChange();
  }

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "R_RIFORNIMENTI");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true
        var enteId = this.$store.state.enteSel.id;
        this.isUserUtiliz = this.$store.state.roleSel.CCode == "UTIL";
        var userId = this.$store.state.user.id;
        if (this.$store.state.roleSel.CCode == "ADM")
          userId = 0;
        this.loadUser();
        this.loadRifornimentiVeicoli(enteId, userId);
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }
  }

  get headersColumns() {
    let tblWidth = this.dimension;
    //console.log(tblWidth);
    if (tblWidth < 1000) return this.headersColumnsRidotte;
    else return this.headersColumnsMain;
  }

  onTableRowClick(riforn, row) {
    // Setto la riga selezionata
    this.rifornimenti.selected = riforn;
    console.log("Rifornimento selezionato: ");
    console.log(this.rifornimenti.selected);

    if (!this.isEditRifornimenti)
      this.tab = "Ultimo rifornimento";
    
    /*
    // Carico le tipologiche dell'elemento selezionato
    this.fillTipologicheERelazioni();
    */

    // Aggiorno i Guid dei carburanti
    for (let i = 0; i < this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1.length; i++ ) {
      this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1[i].guid = Utils.createGuid();
    }
    for (let i = 0; i < this.rifornimenti.selected.ultimoRifornimento.listaDettaglio2.length; i++ ) {
      this.rifornimenti.selected.ultimoRifornimento.listaDettaglio2[i].guid = Utils.createGuid();
    }

    this.onContainerChange();

    this.shouldDisable = true;
    this.isEditActive = false;
    this.isEditRifornimenti = true;
    this.isHidden = false;
  }

  onClickAddCarb1() {
    let quantita = Utils.isNullOrUndefinedOrEmpty(
      this.rifornimenti.selected.quantitaCarb1
    )
      ? 0
      : this.rifornimenti.selected.quantitaCarb1;
    let valore = Utils.isNullOrUndefinedOrEmpty(
      this.rifornimenti.selected.valoreCarb1
    )
      ? 0
      : this.rifornimenti.selected.valoreCarb1;
    if (valore != 0 && quantita != 0) {
      // Aggiungo i valori in griglia
      let newCarb = {
        nCosto: valore,
        nQuantita: quantita,
        guid: Utils.createGuid(),
        hDel: null,
      };

      this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1.push(
        newCarb
      );
      // Aggiorno i totali
      if (
        Utils.isNullOrUndefinedOrEmpty(
          this.rifornimenti.selected.ultimoRifornimento.nCosto1
        )
      )
        this.rifornimenti.selected.ultimoRifornimento.nCosto1 = 0;
      if (
        Utils.isNullOrUndefinedOrEmpty(
          this.rifornimenti.selected.ultimoRifornimento.nQuantita1
        )
      )
        this.rifornimenti.selected.ultimoRifornimento.nQuantita1 = 0;
      this.rifornimenti.selected.ultimoRifornimento.nCosto1 =
        parseFloat(this.rifornimenti.selected.ultimoRifornimento.nCosto1) +
        parseFloat(valore);
      this.rifornimenti.selected.ultimoRifornimento.nQuantita1 =
        parseFloat(this.rifornimenti.selected.ultimoRifornimento.nQuantita1) +
        parseFloat(quantita);

      // Svuoto i 2 campi
      this.rifornimenti.selected.quantitaCarb1 = "";
      this.rifornimenti.selected.valoreCarb1 = "";
    } else {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreInserimentoQuantitaValore");
      this.showHide_alertCarb1(5000);
      return;
      // Messaggio di alert ??
    }
  }

  onClickAddCarb2() {
    let quantita = Utils.isNullOrUndefinedOrEmpty(
      this.rifornimenti.selected.ultimoRifornimento.quantitaCarb2
    )
      ? 0
      : this.rifornimenti.selected.ultimoRifornimento.quantitaCarb2;
    let valore = Utils.isNullOrUndefinedOrEmpty(
      this.rifornimenti.selected.ultimoRifornimento.valoreCarb2
    )
      ? 0
      : this.rifornimenti.selected.ultimoRifornimento.valoreCarb2;
    if (valore != 0 && quantita != 0) {
      // Aggiungo i valori in griglia
      let newCarb = {
        nCosto: valore,
        nQuantita: quantita,
        guid: Utils.createGuid(),
        hDel: null,
      };

      this.rifornimenti.selected.ultimoRifornimento.listaDettaglio2.push(
        newCarb
      );
      // Aggiorno i totali
      if (
        Utils.isNullOrUndefinedOrEmpty(
          this.rifornimenti.selected.ultimoRifornimento.nCosto2
        )
      )
        this.rifornimenti.selected.ultimoRifornimento.nCosto2 = 0;
      if (
        Utils.isNullOrUndefinedOrEmpty(
          this.rifornimenti.selected.ultimoRifornimento.nQuantita2
        )
      )
        this.rifornimenti.selected.ultimoRifornimento.nQuantita2 = 0;
      this.rifornimenti.selected.ultimoRifornimento.nCosto2 =
        parseFloat(this.rifornimenti.selected.ultimoRifornimento.nCosto2) +
        parseFloat(valore);
      this.rifornimenti.selected.ultimoRifornimento.nCosto2 = this.rifornimenti.selected.ultimoRifornimento.nCosto2.toFixed(2);

      this.rifornimenti.selected.ultimoRifornimento.nQuantita2 =
        parseFloat(this.rifornimenti.selected.ultimoRifornimento.nQuantita2) +
        parseFloat(quantita);
      this.rifornimenti.selected.ultimoRifornimento.nQuantita2 = this.rifornimenti.selected.ultimoRifornimento.nQuantita2.toFixed(2);
      // Svuoto i 2 campi
      this.rifornimenti.selected.ultimoRifornimento.quantitaCarb2 = "";
      this.rifornimenti.selected.ultimoRifornimento.valoreCarb2 = "";
    } else {
      // Messaggio di alert ??
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreInserimentoQuantitaValore");
      this.showHide_alertCarb2(5000);
      return;      
    }
  }

  changeKmPercorsi() {
    let kmInizioMese = Utils.isNullOrUndefinedOrEmpty(
      this.rifornimenti.selected.ultimoRifornimento.nKmInizioMese
    )
      ? 0
      : this.rifornimenti.selected.ultimoRifornimento.nKmInizioMese;
    let kmFineMese = Utils.isNullOrUndefinedOrEmpty(
      this.rifornimenti.selected.ultimoRifornimento.nKmFineMese
    )
      ? 0
      : this.rifornimenti.selected.ultimoRifornimento.nKmFineMese;

    if (parseInt(kmFineMese) >= parseInt(kmInizioMese))
      this.rifornimenti.selected.ultimoRifornimento.nKmPercorsi =
        parseInt(kmFineMese) - parseInt(kmInizioMese);
    else {
      this.alertType = "error";
      this.messaggioAlert =
        "I km di fine mese devono essere maggiori uguali rispetto a quelli di inizio mese";
      this.showHide_alertDetail(5000);
    }
  }

  onEditClick() {
    this.shouldDisable = false;
    this.isEditActive = true;
  }

  onSaveClick() {
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
    } else {
      if (parseInt(this.rifornimenti.selected.ultimoRifornimento.nKmFineMese) < 0) {
        this.alertType = "error";
        this.messaggioAlert =
          "I km di fine mese devono avere un valore maggiore uguale a 0";
        this.showHide_alertDetail(8000);
        return;
      }
      else if (parseInt(this.rifornimenti.selected.ultimoRifornimento.nKmFineMese) < 
        parseInt(this.rifornimenti.selected.ultimoRifornimento.nKmInizioMese)) {
        this.alertType = "error";
        this.messaggioAlert =
          "I km di fine mese devono avere un valore maggiore dei km iniziali";
        this.showHide_alertDetail(8000);
        return;
      }

      this.saveRifornimentoVeicoloDto(this.rifornimenti.selected.ultimoRifornimento.id);

      this.isEditActive = false;
      this.shouldDisable = true;
    }
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById(
        "tblMainRifornimenti"
      ).offsetWidth;
      return self.dimension;
    });
  }

  onChiudiMeseClick() {
    // TODO
    // Verificare che siano inseriti i dati di Km fine mese e carburante
    if (
      Utils.isNullOrUndefinedOrEmpty(
        this.rifornimenti.selected.ultimoRifornimento.nKmFineMese
      )
    ) {
      this.alertType = "error";
      this.messaggioAlert = "Inserire i km di fine mese";
      this.showHide_alertDetail(5000);
      return;
    } else {
      // Nessun controllo sul carburante
      /*
      if (Utils.isNullOrUndefinedOrEmpty(this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1))
      {
          this.alertType = "error";
          this.messaggioAlert = "Inserire almeno un dato carburante";
          this.showHide_alertDetail(5000);
          return;        
      }
      else if (this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1.length == 0)
      {
          this.alertType = "error";
          this.messaggioAlert = "Inserire almeno un dato carburante";
          this.showHide_alertDetail(5000);
          return;        
      }
      else
        this.chiudiRifornimentoVeicoloDto();
      */

      if (
        parseInt(this.rifornimenti.selected.ultimoRifornimento.nKmFineMese) < 0
      ) {
        this.alertType = "error";
        this.messaggioAlert =
          "I km di fine mese devono avere un valore maggiore uguale a 0";
        this.showHide_alertDetail(5000);
        return;
      } else this.chiudiRifornimentoVeicoloDto();
    }
  }

  onVerificaClick() {
    var riferimentoId = this.rifornimenti.selected.ultimoRifornimento.id;
    ExcelService.generaExcelSegnalazioniRiferimenti(
      riferimentoId,
      function(data, resp) {
          const blob = new Blob([data]);
          const el = document.createElement("a");
          el.href = window.URL.createObjectURL(blob);
          const regex = /filename=(?<filename>[^,;]+);/g;
          const match = regex.exec(resp.headers['content-disposition']);
          let filename = ""; // or any other value you consider default
          if (typeof match !== 'undefined' && match !== null) {
              filename = match.groups['filename'];
          }
          el.download = filename;
          el.click();
        },
        (fail) => {
          alert(fail);
        },
    )
  }

  onNuovoMeseClick() {
    var idVeicolo = this.rifornimenti.selected.idVeicolo;
    this.getNuovoRifornimentoVeicoloDto(idVeicolo);
  }

  onSbloccaRifornimentoClick() {
    this.SbloccaRifornimentoVeicoloDto();
  }

  closeDetail() {
    this.isEditActive = false;
    this.isEditRifornimenti = false;
    this.onContainerChange();
    this.shouldDisable = true;
    this.isHidden = false;
  }

  showAlertMessage(alertType, localVariableName) {
    this.alertType = alertType;
    this.messaggioAlert = i18n.t(localVariableName);
    this.showHide_alertDetail(5000);
  }

  showHide_alertCarb1(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertCarb1Visible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertCarb1Visible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertCarb2(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertCarb2Visible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertCarb2Visible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      //console.log("hide alert after " + millisecondInterval / 1000 + " seconds");
    }, millisecondInterval);
  }

  closeDeleteCarb1() {
    this.dialogDeleteCarb1 = false;
  }

  deleteItemCarb1(item) {
    this.guidCarb1ToDelete = item.guid;
    this.dialogDeleteCarb1 = true;
  }

  deleteItemConfirmCarb1() {
    for (
      let i = 0;
      i < this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1.length;
      i++
    ) {
      var item =
        this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1[i];
      if (item.guid == this.guidCarb1ToDelete) {
        // Aggiorno i totali
        if (
          Utils.isNullOrUndefinedOrEmpty(
            this.rifornimenti.selected.ultimoRifornimento.nCosto1
          )
        )
          this.rifornimenti.selected.ultimoRifornimento.nCosto1 = 0;
        if (
          Utils.isNullOrUndefinedOrEmpty(
            this.rifornimenti.selected.ultimoRifornimento.nQuantita1
          )
        )
          this.rifornimenti.selected.ultimoRifornimento.nQuantita1 = 0;
        this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1.splice(
          i,
          1
        );
        break;
      }
    }

    this.rifornimenti.selected.ultimoRifornimento.nCosto1 = 0;
    this.rifornimenti.selected.ultimoRifornimento.nQuantita1 = 0;
    for (
      let i = 0;
      i < this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1.length;
      i++
    ) {
      var item =
        this.rifornimenti.selected.ultimoRifornimento.listaDettaglio1[i];

      // Aggiorno i totali
      if (
        Utils.isNullOrUndefinedOrEmpty(
          this.rifornimenti.selected.ultimoRifornimento.nCosto1
        )
      )
        this.rifornimenti.selected.ultimoRifornimento.nCosto1 = 0;
      if (
        Utils.isNullOrUndefinedOrEmpty(
          this.rifornimenti.selected.ultimoRifornimento.nQuantita1
        )
      )
        this.rifornimenti.selected.ultimoRifornimento.nQuantita1 = 0;

      this.rifornimenti.selected.ultimoRifornimento.nCosto1 =
        parseInt(this.rifornimenti.selected.ultimoRifornimento.nCosto1) +
        parseInt(item.nCosto);
      this.rifornimenti.selected.ultimoRifornimento.nQuantita1 =
        parseInt(this.rifornimenti.selected.ultimoRifornimento.nQuantita1) +
        parseInt(item.nQuantita);
    }

    this.dialogDeleteCarb1 = false;
  }

  closeDeleteCarb2() {
    this.dialogDeleteCarb2 = false;
  }

  deleteItemCarb2(item) {
    this.guidCarb2ToDelete = item.guid;
    this.dialogDeleteCarb2 = true;
  }

  deleteItemConfirmCarb2() {
    for (
      let i = 0;
      i < this.rifornimenti.selected.ultimoRifornimento.listaDettaglio2.length;
      i++
    ) {
      var item = this.rifornimenti.selected.ultimoRifornimento.listaDettaglio2[i];
      if (item.guid == this.guidCarb2ToDelete) {
        // Aggiorno i totali
        if (Utils.isNullOrUndefinedOrEmpty(this.rifornimenti.selected.ultimoRifornimento.nCosto2))
          this.rifornimenti.selected.ultimoRifornimento.nCosto2 = 0;
          if (
            Utils.isNullOrUndefinedOrEmpty(this.rifornimenti.selected.ultimoRifornimento.nQuantita2))
              this.rifornimenti.selected.ultimoRifornimento.nQuantita2 = 0;
              this.rifornimenti.selected.ultimoRifornimento.listaDettaglio2.splice(i, 1);
          break;
        }
      }

    this.rifornimenti.selected.ultimoRifornimento.nCosto2 = 0;
    this.rifornimenti.selected.ultimoRifornimento.nQuantita2 = 0;
    for (
      let i = 0;
      i < this.rifornimenti.selected.ultimoRifornimento.listaDettaglio2.length;
      i++
    ) {
      var item = this.rifornimenti.selected.ultimoRifornimento.listaDettaglio2[i];

      // Aggiorno i totali
      if (Utils.isNullOrUndefinedOrEmpty(this.rifornimenti.selected.ultimoRifornimento.nCosto2))
        this.rifornimenti.selected.ultimoRifornimento.nCosto2 = 0;
      if (
        Utils.isNullOrUndefinedOrEmpty(this.rifornimenti.selected.ultimoRifornimento.nQuantita2))
        this.rifornimenti.selected.ultimoRifornimento.nQuantita2 = 0;

      this.rifornimenti.selected.ultimoRifornimento.nCosto2 =
        parseInt(this.rifornimenti.selected.ultimoRifornimento.nCosto2) +
        parseInt(item.nCosto);
      this.rifornimenti.selected.ultimoRifornimento.nQuantita2 =
        parseInt(this.rifornimenti.selected.ultimoRifornimento.nQuantita2) +
        parseInt(item.nQuantita);
    }

    this.dialogDeleteCarb2 = false;
  }

  /// ****************************
  /// DATABASE
  /// ****************************

  loadUser() {
    var self = this;
    console.log("User id:");
    console.log(this.$store.state.user.id);
    UtentiService.getUser(
      this.$store.state.user.id,
      (resp) => {
        console.log("getUser");
        console.log(resp);

        self.eUtente = resp;
      },
      (err) => {
        console.log("Errore getUser");
        self.alertType = "error";
        self.messaggioAlert =
          "Errore di caricamento dei dati dell'utente. Si prega di effettuare di nuovo il login.";
        self.showHide_alertGrid(5000);
      }
    );
  }

  loadRifornimentiVeicoli(enteId, userId) {
    var self = this;

    self.isloading_rifornimenti = true;
    RifornimentiService.getRifornimentiVeicoli(
      enteId, userId,
      (resp) => {
        self.isloading_rifornimenti = false;
        console.log("Rifornimenti Veicoli:");
        console.log(resp);

        self.rifornimenti.source = resp;
      },
      (err) => {
        self.isloading_rifornimenti = false;
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoRifornimentiVeicoli");
        self.showHide_alertGrid(5000);
      }
    );
  }

  saveRifornimentoVeicoloDto(id) {
    var self = this;

    RifornimentiService.updateRifornimentoVeicolo(
      self.rifornimenti.selected,
      (resp) => {
        console.log("updateRifornimentoVeicolo");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia principale
          var rifTemp = Object.assign({}, self.rifornimenti.selected);
          for (let i = 0; i < self.rifornimenti.source.length; i++) {
            var item = self.rifornimenti.source[i];
            if (item.ultimoRifornimento.id == id) {
              self.rifornimenti.source.splice(i, 1);
              self.rifornimenti.source.push(rifTemp);
            }
          }

        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  chiudiRifornimentoVeicoloDto() {
    var self = this;

    RifornimentiService.chiudiRifornimentoVeicolo(
      self.rifornimenti.selected,
      (resp) => {
        console.log("chiudiRifornimentoVeicolo");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Aggiorno la griglia
          var enteId = this.$store.state.enteSel.id;
          var userId = this.$store.state.user.id;
          if (this.$store.state.roleSel.CCode == "ADM")
            userId = 0;

          self.loadRifornimentiVeicoli(enteId, userId);
          self.closeDetail();
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  SbloccaRifornimentoVeicoloDto() {
    var self = this;

    RifornimentiService.sbloccaRifornimentoVeicolo(
      self.rifornimenti.selected,
      (resp) => {
        console.log("SbloccaRifornimentoVeicolo");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Aggiorno la griglia
          var enteId = this.$store.state.enteSel.id;
          var userId = this.$store.state.user.id;
          if (this.$store.state.roleSel.CCode == "ADM")
            userId = 0;
          self.loadRifornimentiVeicoli(enteId, userId);
          self.closeDetail();
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  getNuovoRifornimentoVeicoloDto(idVeicolo) {
    var self = this;

    self.isloading_rifornimenti = true;
    RifornimentiService.getNuovoRifornimentoVeicolo(
      idVeicolo,
      (resp) => {
        self.isloading_rifornimenti = false;
        console.log("Rifornimenti Veicoli:");
        console.log(resp);

        self.alertType = "success";
        self.messaggioAlert =
          "Nuovo rifornimento del mese creato correttamente";
        self.showHide_alertGrid(5000);

        // Aggiorno la griglia
        var enteId = this.$store.state.enteSel.id;
        var userId = this.$store.state.user.id;
        if (this.$store.state.roleSel.CCode == "ADM")
          userId = 0;
        self.loadRifornimentiVeicoli(enteId, userId);
        self.closeDetail();

        //self.rifornimenti.source = resp;
      },
      (err) => {
        self.isloading_rifornimenti = false;
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoRifornimentiVeicoli");
        self.showHide_alertGrid(5000);
      }
    );
  }

  onExportGrid() {
    var enteId = this.$store.state.enteSel.id;
    
    ExcelService.generaExcelRifornimenti(
      enteId,
      function(data, resp) {
          const blob = new Blob([data]);
          const el = document.createElement("a");
          el.href = window.URL.createObjectURL(blob);
          const regex = /filename=(?<filename>[^,;]+);/g;
          const match = regex.exec(resp.headers['content-disposition']);
          let filename = ""; // or any other value you consider default
          if (typeof match !== 'undefined' && match !== null) {
              filename = match.groups['filename'];
          }
          el.download = filename;
          el.click();
        },
        (fail) => {
          alert(fail);
        },
    )
  }
}
</script>
